.App {
  text-align: center;
  background-color: #2D2D2D;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.infoLabel {
  font-family: Helvetica, Verdana, Arial, "sans-serif";
  text-align: center;
  position: relative;
  color: #8C8C8C;
  height: 26px;
  padding-top: 4px;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.accountBlock {
  position: fixed;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 6px;
  margin-bottom: 6px;
  top: 0;
  right: 0;
  z-index: 1;
  color: #8C8C8C;
  text-decoration: underline;
  cursor: pointer;
}

.accountIcon {
  position: relative;
  top: 2px;
  margin-left: 6px;
}

.armchairLogo {
  position: fixed;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 6px;
  margin-bottom: 6px;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.settingsIcon {
  position: fixed;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 6px;
  margin-bottom: 6px;
  bottom: 0;
  right: 0;
  z-index: 1;
  cursor: pointer;
}

.betaTag {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  user-drag: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -ms-user-select: none;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

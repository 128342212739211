.cell {
    height: 170px;
    max-width: 170px;
    width: 20%;
    border-radius: 8px;
    position: relative;
}

.cellContent {
    background-color: #000000;
    border-radius: 8px;
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    cursor: pointer;
}

.cellContent:hover {
    background-color: #00000088;
}

.cellTitle  {
    font-family: Helvetica, Verdana, Arial, "sans-serif";
    margin: 14px;
    color: #FF5F00;
}

.cellPrice {
    font-family: Helvetica, Verdana, Arial, "sans-serif";
    color: #FFFFFF;
    width: 100%;
    height: 100%;
    line-height: 100%;
    text-align: center;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 80%;
    bottom: 0;
}

.cellChangeBlock {
    color: #FFFFFF;
    margin: 14px;
    position: absolute;
    height: 40px;
    bottom: 0;
    right: 0;
}

.cellChange {
    font-family: Helvetica, Verdana, Arial, "sans-serif";
    color: #FFFFFF;
    text-align: center;
}

.cellChangeIcon {
    margin-left: auto;
    margin-right: auto;
    width: 44px;
}

.cellIcon {
    color: #FFFFFF;
    margin: 8px;
    position: absolute;
    width: 44px;
    height: 44px;
    bottom: 0;
}

@media only screen and (max-width: 666px) {
    .cellIcon {
        display: none;
    }
}

@media only screen and (max-width: 595px) {
    .cell {
        width: 33.33%;
    }

    .cellIcon {
        display: block;
    }
}

@media only screen and (max-width: 410px) {
    .cellIcon {
        display: none;
    }
}

@media only screen and (max-width: 339px) {
    .cell {
        width: 100%;
    }

    .cellIcon {
        display: block;
    }
}
.graphFrame {
    position: absolute;
    height: 329px;
    width: 400px;
    background-color: #2D2D2D;
    margin: auto;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 4;
    border-radius: 8px;
}

@media only screen and (max-width: 670px) {
    .graphFrame {
        height: 261px;
        width: 300px;
    }
}

.graphInnerFrame {
    position: relative;
    background-color: #000000;
    margin: 8px;
    margin-top: 9px;
}

.title {
    font-family: Helvetica, Verdana, Arial, "sans-serif";
    text-align: center;
    font-size: 20px;
    color: #FFFFFF;
    margin: 6px;
}

.subtitle {
    font-family: Helvetica, Verdana, Arial, "sans-serif";
    text-align: center;
    color: #8C8C8C;
    margin: 4px;
}

.graphBackgroundView {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

